define('rangy',["rangy-core", 
        "rangy-highlighter", 
        "rangy-cssclassapplier", 
        "rangy-textrange", 
        "rangy-position",

        //"domReady" //"domReady!" forces wait for DOM doc

        /*
        "rangy-serializer",
        "rangy-selectionsaverestore"
        */
        ],
function (core, textrange, highlighter, cssclassapplier, position
//, domReady
/* serializer, selectionsaverestore */
) {

// domReady(function(){
//     core.init();
// });


return core;

});
